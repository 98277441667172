@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/100df27f1825c8b5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/292072416403dd53-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/d082b4d8c3ecfe18-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/1bae2fcc4426dcc8-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/6d09e905cf629aa6-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__albraFont_e179cd';
src: url(/_next/static/media/04f0707b1beb69a5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__albraFont_Fallback_e179cd';src: local("Arial");ascent-override: 80.97%;descent-override: 22.03%;line-gap-override: 38.22%;size-adjust: 97.09%
}.__className_e179cd {font-family: '__albraFont_e179cd', '__albraFont_Fallback_e179cd'
}

@font-face {
font-family: '__albraFont_d5c17e';
src: url(/_next/static/media/292072416403dd53-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__albraFont_Fallback_d5c17e';src: local("Arial");ascent-override: 80.97%;descent-override: 22.03%;line-gap-override: 38.22%;size-adjust: 97.09%
}.__className_d5c17e {font-family: '__albraFont_d5c17e', '__albraFont_Fallback_d5c17e';font-weight: 400;font-style: normal
}

@font-face {
font-family: '__albraFont_d5c17e';
src: url(/_next/static/media/292072416403dd53-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__albraFont_Fallback_d5c17e';src: local("Arial");ascent-override: 80.97%;descent-override: 22.03%;line-gap-override: 38.22%;size-adjust: 97.09%
}.__className_d5c17e {font-family: '__albraFont_d5c17e', '__albraFont_Fallback_d5c17e';font-weight: 400;font-style: normal
}

.range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}
.range-slider[data-vertical] {
  height: 100%;
  width: 8px;
}
.range-slider[data-disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.range-slider .range-slider__thumb {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #2196f3;
}
.range-slider .range-slider__thumb:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 6px rgba(33, 150, 243, 0.5);
}
.range-slider[data-vertical] .range-slider__thumb {
  left: 50%;
}
.range-slider .range-slider__thumb[data-disabled] {
  z-index: 2;
}
.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  background: #51adf6;
}
.range-slider[data-vertical] .range-slider__range {
  left: 50%;
  transform: translate(-50%, 0);
}
.range-slider input[type="range"] {
  -webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
}
.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
}
.range-slider input[type="range"]::-moz-range-thumb {
  width: 0;
  height: 0;
  border: 0;
}
.range-slider input[type="range"]:focus {
  outline: 0;
}

.range-input-slider_rangeSlider__j9tcd input{display:none}
.article_overlay__g1Rt6,.article_latest_articles__ySluI .article_overlay__g1Rt6,.article_carousel__9WMql .article_overlay__g1Rt6{position:absolute;background-image:linear-gradient(180deg, rgba(0, 0, 0, 0.11) 0%, rgba(0, 0, 0, 0.856) 64.62%, rgba(0, 0, 0, 0.918) 84.92%);display:flex;align-items:center;justify-content:center}.article_tag__w_Eai span{padding:0;font-size:12px;font-weight:400}.article_carousel__9WMql{position:relative}.article_carousel__9WMql>div:first-child{border-radius:0}.article_carousel__9WMql>div:first-child img{width:100%;z-index:-10;-o-object-fit:cover;object-fit:cover;position:relative}.article_carousel__9WMql div button.article_group__Zl_g1{z-index:10}.article_latest_articles__ySluI .article_overlay__g1Rt6{align-items:flex-start;justify-content:flex-end}.article_latest_articles__ySluI img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;position:relative}
